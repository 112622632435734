<template>
  <div>
    <v-card-text>
      <v-alert v-show="show" text type="info">No hay data para mostrar</v-alert>
      <iframe
        v-show="url"
        style="border: 2px solid rgb(237, 237, 237)"
        width="100%"
        :height="window.height"
        id="frameID"
        :src="url"
        allowfullscreen
      ></iframe>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-select
          v-model="selected"
          :items="items"
          placeholder="Seleccione un documento"
          item-text="Nombre"
          item-value="Path"
          append-icon="keyboard_arrow_down"
        ></v-select>
        <br />
        <div class="mb-5 text-right">
          <v-btn block class="ma-1" depressed color="primary" @click="setUrl">
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>
<script>
import HomeAPI from "@/api/home";
import { global } from "@/api/global";

export default {
  data() {
    return {
      drawerRight: true,
      url: "",
      selected: null,
      show: true,
      items: [],
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  // watch: {
  //   selected: function () {
  //     this.url = global.baseUrl + this.selected;
  //   },
  // },
  methods: {
    GetEsquemasList() {
      HomeAPI.getEsquemaItems().then((response) => {
        this.items = response.data;
      });
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight - 120;
    },
    setUrl() {
      this.show = false;
      this.url = global.baseUrl + this.selected;
    },
  },
  mounted() {
    this.GetEsquemasList();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>